import Swiper from 'swiper';
import { nodeListToArray } from '../helpers/nodeListToArray';
import * as YouTubeIframeLoader from 'youtube-iframe';
import { debounce } from 'lodash';


export class multiCarousel {
    activeSlides: any[];
    inactiveSlides: any[];
    previousSlide: any;
    activeIndex: any;
    slides: any;
    previousIndex: number; // swiper's previousIndex implementation doesn't work with loop: true so we need to do it manually
    videos: any;
    autoPauseVideos: boolean;
    activeSlideCount: number;
    totalSlideCount: number;
    videoControlButtons: any[];
    secondaryCarousel: HTMLElement;
    primaryCarousel: HTMLElement;
    primarySlider: any;
    secondarySlider: any;
    carouselContainer: HTMLElement;


    constructor(homepageCarouselContainer: HTMLElement) {
        this.secondaryCarousel = homepageCarouselContainer.querySelector('[data-homepage-secondary-carousel]') as HTMLElement;
        this.primaryCarousel = homepageCarouselContainer.querySelector('[data-homepage-primary-carousel') as HTMLElement;
        this.init();
    }


    init() {
        this.secondarySlider = new Swiper(this.secondaryCarousel, {
            containerClass: 'callout-carousel-container',
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            autoHeight: true,
            loop: true,
            autoplayVideo: true,
            autoPauseVideos: true,
            simulateTouch: false,
            preventClicks: true,
            allowTouchMove: false,
            speed: 500
        })

        this.primarySlider = new Swiper(this.primaryCarousel, {
            containerClass: 'callout-carousel-container',
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            autoHeight: false,
            loop: true,
            autoplayVideo: true,
            autoPauseVideos: true,
            simulateTouch: true,
            preventClicks: false,
            allowTouchMove: true,
            on: {
                slideChangeTransitionEnd: this.handleSlide.bind(this),
            },
        });

        this.videos = {};
        this.initCallback(this.primarySlider);
        this.initVisibleVideos;

        this.videoControlButtons = nodeListToArray(document.querySelectorAll('[data-controls-video]'));
        if (this.videoControlButtons) {
            this.setupVideoButtonsEventListener();
        }
        this.primarySlider.controller.control = this.secondarySlider;
    }

    initCallback(slider) {
        this.activeIndex = this.primarySlider.activeIndex;
        this.totalSlideCount = this.primarySlider.slides.length;
        this.primarySlider.updateSlides();
        this.setActiveSlides();
        this.slides = this.primarySlider.slides;

        if (window.matchMedia('(min-width: 640px)')) {
            this.initVisibleVideos();
        }

        window.addEventListener(
            'resize',
            debounce(() => {
                if (window.matchMedia('(min-width: 640px)')) {
                    this.initVisibleVideos();
                }
            }))

    }


    handleSlide() {
        if (this.primarySlider) {


            this.previousIndex = this.activeIndex;
            this.previousSlide = this.primarySlider.slides[this.previousIndex];
            this.activeIndex = this.primarySlider.activeIndex;
            this.setActiveSlides();
            this.initVisibleVideos();
            if (this.autoPauseVideos) {
                this.pauseInactiveVideos();
            }
        }

    }

    setActiveSlides() {
        this.activeSlideCount = this.primarySlider.originalParams.slidesPerView as number;
        this.activeSlides = [];
        this.inactiveSlides = [];
        const lastIndex = (this.activeIndex + this.activeSlideCount - 1) % (this.totalSlideCount - 1);
        for (let i = 0; i < this.primarySlider.slides.length; i++) {
            const slide = this.primarySlider.slides[i];
            const inRange = lastIndex < this.activeIndex ?
                i >= this.activeIndex || i < lastIndex
                : i >= this.activeIndex && i <= lastIndex;
            if (inRange) {
                this.activeSlides.push({ slide: slide, index: i });
                this.enableSlide(slide);
            }
            else {
                this.inactiveSlides.push({ slide: slide, index: i });
                this.disableSlide(slide);
            }
        }
    }


    initVisibleVideos() {
        this.activeSlides.forEach(slideInfo => {
            const slide = slideInfo.slide;
            const activeSlideVideo = slide.querySelector('[data-video-type]');
            if (activeSlideVideo) {
                const videoType = activeSlideVideo.getAttribute('data-video-type');
                if (videoType == 'youtube') {
                    this.youtubeInit(slide, slideInfo.index);
                }
                else {
                    this.nativeVideoInit(slide, slideInfo.index);
                }
            }
        });
    }

    setupVideoButtonsEventListener() {
        this.videoControlButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                this.togglePlayerState(button);
            });
        });
    }

    pauseInactiveVideos() {
        this.inactiveSlides.forEach(slideInfo => {
            if (this.videos[slideInfo.index]) {
                this.pauseVid(slideInfo.slide, slideInfo.index)
            }
        })
    }


    youtubeInit(slide, index) {
        const youtubeIframe = slide.querySelector('iframe');
        const secondarySlide = this.secondarySlider.slides[index]
        const slideControl = secondarySlide.querySelector('[data-controls-video]');
        if (!youtubeIframe.src) {
            YouTubeIframeLoader.load(YT => {
                const playerID = `youtube-player-${this.primaryCarousel.getAttribute('data-unique-id')}-${index}`;
                youtubeIframe.id = playerID;
                youtubeIframe.src = youtubeIframe.getAttribute('data-src');

                const youtubePlayer = new YT.Player(playerID, {
                    playerVars: { rel: 0, showinfo: 0, ecver: 2 },
                    events: {
                        'onReady': () => {
                            this.playVid(slide, index)
                        },
                        'onStateChange': () => {
                            let state = youtubePlayer.getPlayerState()
                            if (state == 0 || state == 2) {
                                this.togglePlayButton(slideControl, false)
                            }
                            if (state == 1) {
                                this.togglePlayButton(slideControl, true)
                            }
                        }
                    }
                });
                this.videos[index] = { type: "youtube", player: youtubePlayer };
            });
        }

    }

    nativeVideoInit(slide, index) {
        const videoElement = slide.querySelector('video');
        const secondarySlide = this.secondarySlider.slides[index]
        const slideControl = secondarySlide.querySelector('[data-controls-video]');
        if (videoElement) {
            const videoSource = videoElement.querySelector('source');
            if (!videoSource.src) {
                videoSource.src = videoSource.getAttribute('data-src');
                videoElement.load();
                videoElement.addEventListener('loadeddata', () => {
                    this.playVid(slide, index)
                    videoElement.addEventListener('ended', (event) => {
                        this.togglePlayButton(slideControl, false)
                    });
                });
                this.videos[index] = { type: "native", player: videoElement };

            }
        }
    }

    playVid(slide, index) {
        const video = this.videos[index];
        const player = video.player;
        const secondarySlide = this.secondarySlider.slides[index]
        const slideControl = secondarySlide.querySelector('[data-controls-video]');

        if (video.type == 'youtube') {
            if (player.getPlayerState() != 1) {
                player.playVideo();
            }
        }

        else {
            player.play();
        }

        this.togglePlayButton(slideControl, true)
    }

    pauseVid(slide, index) {
        const video = this.videos[index];
        const player = video.player;
        const secondarySlide = this.secondarySlider.slides[index]
        const slideControl = secondarySlide.querySelector('[data-controls-video]');

        if (video.type == 'youtube') {
            if (player.getPlayerState() == 1) {
                player.pauseVideo();
            }
        }
        else {
            if (!player.paused) {
                player.pause();
            }
        }

        this.togglePlayButton(slideControl, false)
    }

    togglePlayButton(el, boolean) {
        el.setAttribute('aria-pressed', boolean)
    }

    togglePlayerState(button) {
        let slide = this.primarySlider.slides[this.primarySlider.activeIndex]
        let index = this.primarySlider.activeIndex
        let video = this.videos[index];
        let player = video.player;

        if (video.type == 'youtube') {

            if (player.getPlayerState() == 1) {
                this.pauseVid(slide, index)
                this.togglePlayButton(button, false)
            }
            else {
                this.playVid(slide, index)
                this.togglePlayButton(button, true)
            }
        }
        else if (video.type == 'native') {
            if (player.paused) {
                player.play()
                this.togglePlayButton(button, true)
            }
            else {
                this.pauseVid(slide, index)
                this.togglePlayButton(button, false)

            }
        }
    }


    disableSlide(slide) {
        // disable tabbing on tabbable content by default to stop trapping keyboard, and hide from screenreader users
        const tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            item.tabIndex = -1;
        });
        slide.setAttribute('aria-hidden', 'true');
    }

    enableSlide(slide) {
        // enable tabbing on active slide and show to screenreader users
        const tabbableContent = Array.prototype.slice.call(slide.querySelectorAll('a, input, button, area, object, select, iframe, video, audio'));
        tabbableContent.forEach(item => {
            if (!item.hasAttribute('data-prevent-focus')) {
                item.tabIndex = 0;
            }
        });
        slide.setAttribute('aria-hidden', 'false');
    }



}