import { multiCarousel } from '../../modules/multi-carousel';
import { nodeListToArray } from '../../helpers/nodeListToArray';
import { SimpleVideoControls } from '../../modules/simple-video-controls';

(function () {
    // Gallery Init
    let gallery = document.querySelector('[data-homepage-carousel-container]') as HTMLElement;
    new multiCarousel(gallery);
})();


